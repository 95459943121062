
.education-wrapper {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 60px;
    }
  }
}
.bottom-btn {
  text-align: center;
}
.success-content {
  margin-top: 155px;
  text-align: center;
  .iconfont {
    color: #1FD18C;
    font-size: 52px;
  }
  .text {
    font-size: 24px;
    margin: 20px 0 0;
    font-weight: bold;
  }
}
.total-score {
  position: absolute;
  top: 20px;
  right: 20px;
}
