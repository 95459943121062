
.create-content {
  margin: 60px 0;
}
.big-title {
  width: 380px;
  font-size: 16px;
}
:deep(.logo-uploader) {
  width: 120px;
  height: 120px;
  .el-upload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F7F7;
  }
  .logo-cover {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
  .logo-uploader-icon {
    color: #999;
    font-size: 24px;
  }
  &.logo-uploader-mul {
    display: flex;
    width: 100%;
    .el-upload {
      &.el-upload--picture-card {
        width: 120px;
        height: 120px;
        border: none;
        background: #F7F7F7;
      }
    }
    .el-upload-list__item {
      width: 120px;
      height: 120px;
    }
  }
}
.message {
  width: 380px;
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-top: 4px;
}
